import { isEmpty, intersection } from 'lodash'
import ROLE from '@/utils/enums/Role'

const validatePermissions = (requiresPermissions, userPermissions) => {
  if (
    requiresPermissions &&
    Array.isArray(requiresPermissions) &&
    userPermissions &&
    userPermissions.length > 0
  ) {
    // Check if the user has the required permission
    const isAllow = !isEmpty(intersection(userPermissions, requiresPermissions))
    if (!isAllow) {
      console.log('permission: ', [...requiresPermissions])
      console.log('not allow permission')

      return false
    }
  }

  return true
}

const validateRole = (requiresRoles, userRole) => {
  if (requiresRoles && Array.isArray(requiresRoles) && userRole) {
    return requiresRoles.includes(userRole)
  }

  return true
}

const isReadOnlyRole = (userRole) => {
  return validateRole([ROLE.READ_ONLY], userRole)
}

export { validatePermissions, validateRole, isReadOnlyRole }
