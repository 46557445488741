import Api from '@/services/api'
import dayjs from 'dayjs'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    timeslotDetails: null,
    timeslotAvailableUsers: [],
  },

  mutations: {
    setTimeSlotList(state, timeslots) {
      state.list = timeslots
    },
    setTimeSlotListMeta(state, meta) {
      state.listMeta = meta
    },
    setTimeSlotAvailableUsers(state, list) {
      state.timeslotAvailableUsers = list
    },
  },

  actions: {
    async getTimeSlotListByMonth({ commit, rootGetters }, params = {}) {
      const dateStart =
        params && params.dateStart
          ? params.dateStart
          : dayjs().startOf('month').utc().format()
      const dateEnd =
        params && params.dateEnd
          ? params.dateEnd
          : dayjs().endOf('month').utc().format()

      let url = `/events/time_slots?include=event.category,event.building,eventConfirmation&filter[date_start]=${dateStart}&filter[date_end]=${dateEnd}&paginate=false`

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        url += `&filter[building_id]=${filteredBuilding}`
      }
      const { data } = await Api.get(url)
      commit('setTimeSlotList', data.data)
      commit('setTimeSlotListMeta', data.meta)
      return data.data
    },

    async getTimeSlotListAvailableUsers({ commit }, params = {}) {
      let url = `/events/${params.eventId}/time_slots/${params.id}/available_users?paginate=false`
      if (params.buildingId) {
        url += `&filter[building_id]=${params.buildingId}`
      }
      if (params.propertyId) {
        url += `&filter[property_id]=${params.propertyId}`
      }
      const { data } = await Api.get(url)
      commit('setTimeSlotAvailableUsers', data.data)
    },
  },
}
