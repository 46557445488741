import Property from '@/models/Property'
import { get } from 'lodash'
import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    propertyDetails: null,
    totalProperties: 0,
    params: {
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['name'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        search: '',
      },
      filter: {
        search: '',
      },
      status: 0,
    },
    scrollPosition: 0,
  },

  mutations: {
    setPropertyList(state, properties) {
      state.list = properties
    },

    setPropertyDetails(state, property) {
      if (property instanceof Property) {
        state.propertyDetails = property
      } else {
        state.propertyDetails = new Property(property)
      }
    },

    clearPropertyList(state) {
      state.list = []
    },

    setPropertyListMeta(state, meta) {
      state.listMeta = meta
    },

    clearPropertyDetails(state) {
      state.propertyDetails = null
    },

    setPropertyTotals(state, total) {
      state.totalProperties = total
    },

    setParams(state, params) {
      state.params = params
    },

    setScrollPosition(state, position) {
      state.scrollPosition = position
    },
  },

  actions: {
    async getProperties({ commit, rootGetters }, params) {
      let res = null

      if (params) {
        const query = Property.page(params.options.page || 1)
          .limit(params.options.itemsPerPage || 10)
          .orderBy(params.options.sort)
          .params({ paginate: true })

        switch (params.status) {
          case 0:
            query.where('status', 'active')
            break
          case 1:
            query.where('status', 'inactive')
            break
        }

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        let filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding) {
          query.where('building_id', filteredBuilding)
        }

        query.include(['building', 'createdBy'])

        res = await query.get()
        commit('setPropertyList', res.data)
        commit('setPropertyListMeta', res.meta)
        if (get(res, 'meta.total', 0) > 0) {
          commit('setPropertyTotals', get(res, 'meta.total', 0))
        }
      } else {
        res = await Property.params({ paginate: false }).get()
        return res.data
      }
    },

    async getPropertiesByBuilding({ state }, buildingId) {
      const { data } = await Api.get(`properties/${buildingId}/list`)
      return data.data
    },

    async getPropertyDetails({ commit }, id) {
      const { data } = await Api.get(`properties/${id}`)
      commit('setPropertyDetails', data.data)
    },

    async createProperty({ commit }, formData) {
      const { data } = await Api.post(`properties`, formData)
      commit('setPropertyDetails', data.data)
    },

    async updateProperty({ commit }, formData) {
      const { data } = await Api.put(`properties/${formData.id}`, formData)
      commit('setPropertyDetails', data.data)
    },

    async bulkActionProperty({ commit }, formData) {
      await Api.post(`properties/bulk-action`, formData)
    },

    async duplicateProperty({ commit }, formData) {
      const { data } = await Api.post(`properties/${formData.id}/duplicate`)
      return data.data
    },

    async exportToExcel({ commit }, params) {
      const queryParams = new URLSearchParams({
        page: params.options.page || 1,
        sort: params.options.sort || '',
        include: ['createdBy', 'building'],
      })

      switch (params.status) {
        case 0:
          queryParams.append('filter[status]', 'active')
          break
        case 1:
          queryParams.append('filter[status]', 'inactive')
          break
      }

      const url = `properties/export?${queryParams.toString()}`

      const file = await Api.get(url, {
        responseType: 'blob',
      })
      return file
    },
  },
}
