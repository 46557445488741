import STATUS from '@/utils/enums/BookingStatus'
import Model from './Model'
import {
  convertToDayjs,
  dateFormat,
  dateOnlyFormat,
  timeOnlyFormat,
} from '@/utils/date'
import { capitalize } from '@/utils/helpers'
import { find, get } from 'lodash'

export default class Booking extends Model {
  resource() {
    return 'bookings2'
  }

  get userName() {
    if (!this.user) return
    return this.user.full_name
  }

  get phoneNumber() {
    if (!this.user) return
    return this.user.phone_number
  }

  get buildingName() {
    if (!this.building) return
    return this.building.name
  }

  get serviceTitle() {
    if (!this.bookable) return
    return this.bookable.title
  }

  get serviceType() {
    if (!this.bookable) return
    return capitalize(this.bookable.type)
  }

  get statusName() {
    return capitalize(this.status)
  }

  get statusTier() {
    const curr = find(STATUS, { value: this.status })
    return curr ? curr.tier : 1
  }

  get startDate() {
    if (!this.datetime_start) return
    return dateFormat(this.datetime_start)
  }

  get endDate() {
    if (!this.datetime_end) return
    return dateFormat(this.datetime_end)
  }

  get statusLabel() {
    return this.deleted_at ? 'Deleted' : this.status
  }

  get bookingDate() {
    if (!this.startDate) return 'NA'

    let start = convertToDayjs(this.startDate)
    let end = null
    let isSameMonth = false

    if (this.bookable && this.bookable.has_end_time && this.endDate) {
      end = convertToDayjs(this.endDate)

      isSameMonth =
        start.get('month') === end.get('month') &&
        start.get('date') === end.get('date') &&
        start.get('year') === end.get('year')

      end = this.endDate
    }

    return isSameMonth
      ? `${dateOnlyFormat(this.datetime_start)} ${timeOnlyFormat(
          this.datetime_start
        )} - ${timeOnlyFormat(this.datetime_end)}`
      : `${this.startDate}${end ? ` - ${end}` : ''}`
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }

  get updatedDate() {
    return dateFormat(this.updated_at)
  }

  get deletedDate() {
    return dateFormat(this.deleted_at)
  }

  get property() {
    return get(this.user, 'unit_number', '')
  }
}
