import Model from './Model'
import DATETIME_FORMAT from '@/utils/enums/DatetimeFormat'
import { dateFormat, eventDateOnlyFormat, timeOnlyFormat } from '@/utils/date'

export default class Event extends Model {
  resource() {
    return 'events/list'
  }

  get buildingName() {
    if (!this.building) return 'NA'
    return this.building.name
  }

  get categoryLabel() {
    if (!this.category) return 'NA'
    return this.category.label
  }

  get eventDate() {
    if (!this.next_event_date) return 'NA'
    return `${eventDateOnlyFormat(
      this.next_event_date,
      DATETIME_FORMAT.shortDateFormat
    )} ${timeOnlyFormat(this.next_event_date)}`
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }
}
