import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import '@mdi/font/css/materialdesignicons.css'
import env from '../services/env'

Vue.use(Vuetify)

const iconfont = 'mdiSvg'
var theme = {
  primary: '#e66829',
  secondary: '#5b3e97',
  accent: '#8c9eff',
  error: '#c21e51',
  grey: {
    base: '#ece6d8',
    lighten3: '#f1f3f4',
  },
}

switch (env.domain) {
  case 'esperance':
    theme.primary = '#5B724B'
    theme.grey.base = '#F9F3F0'
    break
  case 'brookland':
    theme.primary = '#505D42'
    theme.grey.base = '#FFFFFF'
    break
  case 'samfordgrove':
    theme.primary = '#382e48'
    theme.grey.base = '#F9F3F0'
    break
  case 'fairway':
    theme.primary = '#44273b'
    theme.grey.base = '#f5f1f4'
    break
  case 'providence':
    theme.primary = '#83d97d'
    theme.grey.base = '#83d97d'
    break
  case 'serenitypines':
    theme.primary = '#3887D4'
    theme.grey.base = '#CACACA'
    break
  case 'liven':
    theme.primary = '#0F144F'
    theme.grey.base = '#EFEAE8'
    break
  case 'majestic':
    theme.primary = '#956E41'
    theme.grey.base = '#111F2B'
    break
  case 'maleny':
    theme.primary = '#297FD3'
    theme.grey.base = '#F4F4F4'
    break
  case 'ingenia':
    theme.primary = '#00AEC7'
    theme.grey.base = '#F9FBFD'
    break
}

const vuetify = new Vuetify({
  icons: {
    iconfont,
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: theme,
    },
  },
})

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: iconfont,
})

export default vuetify
