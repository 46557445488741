export const PENDING_BOOKING = 'pending'
export const DECLINED_BOOKING = 'declined'
export const ACCEPTED_BOOKING = 'accepted'
export const CANCELLED_CALENDAR = 'cancelled'
export const DONE_CALENDAR = 'done'

const STATUS = [
  {
    text: 'Pending',
    value: 'pending',
    tier: 1,
  },
  {
    text: 'Declined',
    value: 'declined',
    tier: 2,
  },
  {
    text: 'Accepted',
    value: 'accepted',
    tier: 2,
  },
  {
    text: 'Cancelled',
    value: 'cancelled',
    tier: 3,
  },
  {
    text: 'Done',
    value: 'done',
    tier: 3,
  },
]

export default STATUS
