import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class Property extends Model {
  resource() {
    return 'properties'
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }

  get buildingName() {
    if (!this.building) return 'NA'
    return this.building.name
  }

  get createdBy() {
    if (!this.created_by) return 'NA'
    return this.created_by.full_name
  }

  get isActive() {
    return this.status === 'active'
  }
}
