const dateFormat = 'MMM DD, YYYY'
const shortDateFormat = 'DD/MM/YYYY'
const timeFormat = 'hh:mm A'
const eventDateFormat = 'ddd DD/MM/YYYY'
const dateFormatYMD = 'YYYY-MM-DD'
const timeFormatHMS = 'HH:mm:ss'
const isoDateFormat = 'YYYY-MM-DD'

const DATETIME_FORMAT = {
  dateFormat: dateFormat,
  shortDateFormat: shortDateFormat,
  timeFormat: timeFormat,
  eventDateFormat: eventDateFormat,
  format: `${dateFormat} ${timeFormat}`,
  dateFormatYMD: dateFormatYMD,
  timeFormatHMS: timeFormatHMS,
  isoDateFormat: isoDateFormat,
}

export default DATETIME_FORMAT
