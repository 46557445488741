export default [
  {
    path: 'properties',
    name: 'properties',
    meta: {
      requiresPermissions: ['VIEW_PROPERTY'],
    },
    component: () =>
      import(/* webpackChunkName: "properties" */ '@/views/Home/Property'),
  },
  {
    path: 'properties/create',
    name: 'property.create',
    meta: {
      requiresPermissions: ['CREATE_PROPERTY'],
    },
    component: () =>
      import(
        /* webpackChunkName: "property.create" */ '@/views/Home/Property/CreateProperty'
      ),
  },
  {
    path: 'properties/:id',
    name: 'property.details',
    meta: {
      requiresPermissions: ['UPDATE_PROPERTY'],
    },
    component: () =>
      import(
        /* webpackChunkName: "property.details" */ '@/views/Home/Property/PropertyDetails'
      ),
  },
]
