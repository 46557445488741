import DATETIME_FORMAT from '@/utils/enums/DatetimeFormat'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import toObject from 'dayjs/plugin/toObject'
import objectSupport from 'dayjs/plugin/objectSupport'
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(toObject)
dayjs.extend(objectSupport)

function convertToDayjs(date) {
  return date ? dayjs.utc(date).local() : false
}

function dateFormat(date, format = DATETIME_FORMAT.format) {
  return date ? convertToDayjs(date).format(format) : ''
}

function dateOnlyFormat(date, format = DATETIME_FORMAT.dateFormat) {
  return date ? convertToDayjs(date).format(format) : ''
}

function eventDateOnlyFormat(date, format = DATETIME_FORMAT.eventDateFormat) {
  return date ? convertToDayjs(date).format(format) : ''
}

function timeOnlyFormat(date, format = DATETIME_FORMAT.timeFormat) {
  return date ? convertToDayjs(date).format(format) : ''
}

function dateForHuman(date) {
  return date ? convertToDayjs(date).from() : ''
}

function isSameDate(dateA, dateB) {
  return convertToDayjs(dateA).isSame(convertToDayjs(dateB), 'day')
}

function getDefaultDatetime(time) {
  return dayjs(
    `${dayjs().format('YYYY-MM-DD')} ${time}`,
    'YYYY-MM-DD hh:mm A'
  ).toISOString()
}

function getStartAndEndTime(timeObject) {
  let startTime = dayjs(timeObject.start).format('HH:mm')
  let endTime = dayjs(timeObject.end).format('HH:mm')
  return `${startTime} - ${endTime}`
}

function dateFormatYMDFunc(timeString) {
  return dayjs(timeString).format(DATETIME_FORMAT.dateFormatYMD)
}

function compareWithCurrentTime(dateTimeString) {
  const currentTime = dayjs().utc()
  const compareTime = dayjs(dateTimeString).utc()
  if (compareTime.isBefore(currentTime)) {
    return true
  }
  return false
}

function changeDateInString(originalString, newDateString) {
  let indexOfT = originalString.indexOf('T')

  if (indexOfT !== -1) {
    let timePart = originalString.slice(indexOfT)
    let newString = newDateString + timePart
    return newString
  } else {
    return originalString
  }
}

function checkTimeDifferenceDivisible(schedule, minutes) {
  let divisor = minutes * 60 * 1000

  for (let day in schedule) {
    if (schedule.hasOwnProperty(day) && schedule[day]?.open) {
      let startTime = new Date(schedule[day].start)
      let endTime = new Date(schedule[day].end)

      let timeDifference = endTime.getTime() - startTime.getTime()

      if (timeDifference % divisor !== 0) {
        return true
      }
    }
  }

  return false
}
function combineDateAndTimeFromObject(day, time) {
  const combineDate = dayjs(day).toObject()
  const combineTime = dayjs(time).toObject()
  const { years, months, date } = combineDate
  const { hours, minutes, seconds, milliseconds } = combineTime
  return dayjs({
    years,
    months,
    date,
    hours,
    minutes,
    seconds,
    milliseconds,
  })
}

export {
  convertToDayjs,
  dateFormat,
  dateOnlyFormat,
  timeOnlyFormat,
  dateForHuman,
  eventDateOnlyFormat,
  isSameDate,
  getDefaultDatetime,
  getStartAndEndTime,
  dateFormatYMDFunc,
  compareWithCurrentTime,
  changeDateInString,
  checkTimeDifferenceDivisible,
  combineDateAndTimeFromObject,
}
