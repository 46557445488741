const REQUEST_BOOKING = 'request'
const EXTERNAL_BOOKING = 'external'
const NO_BOOKING = 'none'
const BOOKING_CALENDAR = 'calendar'

const BOOKING_OPTION = [
  {
    text: 'Request Booking',
    value: REQUEST_BOOKING,
  },
  {
    text: 'External Booking',
    value: EXTERNAL_BOOKING,
  },
  {
    text: 'No Booking',
    value: NO_BOOKING,
  },
  {
    text: 'Booking Calendar',
    value: BOOKING_CALENDAR,
  },
]

const TIME_SLOT_INCREMENTS = [
  {
    text: '15 Minutes',
    value: 15,
  },
  {
    text: '30 Minutes',
    value: 30,
  },
  {
    text: '1 Hour',
    value: 60,
  },
  {
    text: '2 Hour',
    value: 120,
  },
  {
    text: '3 Hour',
    value: 180,
  },
]

const DEFAULT_WORKING_HOURS = {
  start: '08:00 AM',
  end: '06: 00 PM',
}

const DAY_OF_WEEKS_OBJECT = {
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
  sun: 0,
}

const DAY_OF_WEEKS = [
  {
    text: 'Mon',
    value: 'mon',
  },
  {
    text: 'Tues',
    value: 'tue',
  },
  {
    text: 'Wed',
    value: 'wed',
  },
  {
    text: 'Thurs',
    value: 'thu',
  },
  {
    text: 'Fri',
    value: 'fri',
  },
  {
    text: 'Sat',
    value: 'sat',
  },
  {
    text: 'Sun',
    value: 'sun',
  },
]

export {
  NO_BOOKING,
  DAY_OF_WEEKS,
  REQUEST_BOOKING,
  EXTERNAL_BOOKING,
  BOOKING_CALENDAR,
  BOOKING_OPTION,
  TIME_SLOT_INCREMENTS,
  DEFAULT_WORKING_HOURS,
  DAY_OF_WEEKS_OBJECT,
}
